<template>
  <div class="animated fadeIn">
    <b-card no-body>
      <b-container fluid class="pb-3">
        <b-row class="pt-3">
          <b-col />
        </b-row>

        <b-row v-if="dataTable.visible">
          <b-col>
            <chains-table ref="chains" />
          </b-col>
        </b-row>
      </b-container>
    </b-card>
  </div>
</template>

<script>
import ChainsTable from "@/views/RelationshipAdministrative/ChainsTable";

export default {
  name: "ChainsManager",
  components: {
    ChainsTable
  },
  data: function() {
    return {
      filteringPanel: {
        selected: {},
        loaded: false,
        filters: [
          {
            type: "select",
            dataType: "string",
            tooltip: "Account name",
            title: "Account Name",
            name: "account",
            trackby: "id",
            label: "label",
            options: [],
            selected: {},
            multiple: true,
            async: true,
            loading: false,
            startsWith: false
          },
          {
            type: "daterange",
            dataType: "datetime",
            title: "Invoice Date",
            tooltip: "Invoice period",
            defaultRange: "Last 90 days",
            name: "period"
          }
        ]
      },
      dataTable: {
        visible: true
      }
    };
  },
  computed: {},
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.dataTable.isLoading = true;

      this.dataTable.dataSet = [];

      this.$refs["chains"].getData();
    }
  },
  watch: {}
};
</script>

<style></style>
