<template>
  <div class="animated fadeIn">
    <table-custom
      ref="dataTable"
      :name="`${$customTable.getCustomTableName($route.meta.module)}`"
      :loading="dataTable.isLoading"
      :data="dataTable.dataSet"
      :options="dataTable.options"
      @filter="onFilter"
    >
      <div slot="afterFilter">
        <b-row v-if="showAddButton">
          <b-col>
            <b-button-group>
              <b-button
                variant="outline-dark"
                title="Add Chain"
                :disabled="jobInProgress"
                @click="addItem()"
              >
                <font-awesome-icon icon="plus" /> Create
              </b-button>
              <b-button
                class="ml-1 mr-1"
                variant="outline-dark"
                title="Edit blacklist"
                :disabled="jobInProgress"
                @click="editBlackList()"
              >
                <font-awesome-icon icon="ban" /> Blacklist
              </b-button>
              <b-button
                :disabled="+job.enabled === 0"
                id="job-state-button"
                variant="outline-dark"
                title="Run"
                @click="updateJobState()"
              >
                <b-spinner v-if="jobInProgress" small type="grow" />
                <span v-if="!jobInProgress"
                  ><font-awesome-icon icon="play" /> Run</span
                >
                <span v-if="jobInProgress">Stop</span>
              </b-button>
            </b-button-group>

            <b-popover
              v-if="job.status"
              target="job-state-button"
              triggers="hover"
              placement="right"
            >
              <!--
              <b-row>
                <b-col cols="5">
                  <strong>Status: </strong>
                </b-col>
                <b-col cols="7">
                  {{ job.status }}
                </b-col>
              </b-row>
              -->
              <b-row>
                <b-col cols="5">
                  <strong>Progress: </strong>
                </b-col>
                <b-col cols="7">
                  <b-progress :max="job.total">
                    <b-progress-bar
                      :value="job.current_stage"
                      :label="
                        `${(
                          (+job.current_stage / job.total_stages) *
                          100
                        ).toFixed(2)}%`
                      "
                    ></b-progress-bar>
                  </b-progress>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="5">
                  <strong>Updated: </strong>
                </b-col>
                <b-col cols="7">
                  {{ job.updated_at }}
                </b-col>
              </b-row>
            </b-popover>
          </b-col>
        </b-row>
      </div>
      <div slot="custom-actions" slot-scope="props">
        <div class="btn-group">
          <button
            class="btn btn-warning btn-sm"
            title="Blacklist chain name"
            @click="blacklistItem(props.row.ID)"
          >
            <b-spinner v-if="props.row.isLoading" small type="grow" />
            <font-awesome-icon v-if="!props.row.isLoading" icon="ban" />
          </button>

          <button
            class="btn btn-primary btn-sm"
            @click="viewItem(props.row.ID)"
          >
            <font-awesome-icon icon="eye" />
          </button>

          <button
            class="btn btn-success btn-sm"
            @click="editItem(props.row.ID)"
          >
            <font-awesome-icon icon="pencil-alt" />
          </button>
          <button
            class="btn btn-danger btn-sm"
            @click="deleteItem(props.row.ID)"
          >
            <font-awesome-icon icon="trash" />
          </button>
        </div>
      </div>
    </table-custom>

    <b-modal
      ref="blacklisted-kw-modal"
      centered
      hide-footer
      size="lg"
      :title="blacklistModal.title"
    >
      <b-card>
        <b-card-body>
          <b-overlay
            :show="blacklistModal.isLoading"
            :opacity="0.5"
            spinner-variant="secondary"
            rounded="sm"
          >
            <inline-input
              :value="blacklistModal.data"
              :rows="20"
              @changed="onBlacklistChange"
              label="Blacklist"
              :hide-label="true"
              :readonly="false"
              :mode="$constants.FORMCONTROLMODE.EDIT"
              :required="false"
              :is-text-area="true"
            />
          </b-overlay>
        </b-card-body>
      </b-card>

      <hr />
      <div class="form-row d-flex  justify-content-end">
        <b-button
          variant="outline-dark"
          class="m-1"
          @click="saveBlackListModal()"
          :disabled="blacklistModal.isLoading"
        >
          <font-awesome-icon icon="save" /> Save
        </b-button>
        <b-button
          variant="outline-dark"
          class="m-1"
          @click="closeBlackListModal()"
        >
          <font-awesome-icon icon="times" /> Close
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Vue from "vue";
import TableCustom from "@/components/TableCustom";
import InlineInput from "@/components/InlineInput";

export default {
  props: {
    showAddButton: {
      type: Boolean,
      default: true
    }
  },
  name: "ChainsTable",
  components: {
    TableCustom,
    InlineInput
  },
  data: function() {
    return {
      rawData: {},
      appliedFilters: [],
      blacklistModal: {
        isLoading: false,
        title: "Blacklisted keywords",
        data: ""
      },
      job: {
        inProgress: false,
        completed: 0,
        total: 100
      },
      dataTable: {
        isLoading: false,
        dataSet: [],
        options: {
          columns: [
            "ID",
            "Name",
            "Approved",
            "Accounts",
            "Description",
            "Created",
            "Modified",
            "Actions"
          ],
          filterable: [
            "ID",
            "Name",
            "Approved",
            "Accounts",
            "Description",
            "Created",
            "Modified"
          ],
          footerHeadings: false,
          perPage: 50,
          //showActions: true,
          showCustomActions: true
        }
      }
    };
  },
  computed: {
    jobInProgress() {
      return +this.job.current_stage > 0;
    }
  },
  async mounted() {
    this.getJobStatus();
  },
  methods: {
    onFilter() {},

    async getData(payload) {
      let self = this;

      this.dataTable.isLoading = true;

      this.dataTable.dataSet = [];

      this.appliedFilters = payload;

      let method = "",
        url = "";

      method = "post";
      url = "chains";

      return this.$api[method](url, payload)
        .then(response => {
          self.dataTable.isLoading = false;

          self.$emit("loaded", response.length);

          response.forEach(i => (i.isLoading = false));

          self.dataTable.dataSet = response;

          if (response.length === 0) return;
        })
        .catch(error => {
          console.log(error);
          self.dataTable.isLoading = false;
          self.$form.msgBoxOk("Error occured");
        });
    },
    async updateJobState() {
      if (this.jobInProgress) {
        let confirm = await this.$form.showConfirmation(
          `Job will be stopped. Do you want to proceed?`
        );

        if (!confirm) return;

        let res = await this.$api.get(`job/chains:update/stop`);

        this.$form.makeToastInfo(res.message);
      } else {
        let confirm = await this.$form.showConfirmation(
          `Job will be started. Do you want to proceed?`
        );

        if (!confirm) return;

        let res = await this.$api.get(`job/start/chains`);

        this.$form.makeToastInfo(res.message);
      }
    },
    async getJobStatus() {
      let res = await this.$api.get(`job/chains:update/status`);
      this.job = res;

      console.log("getJobStatus.response", JSON.stringify(res));

      setTimeout(() => this.getJobStatus(), 5000);
    },

    addItem: function() {
      this.$router.push({
        name: "Chain submission",
        params: {
          action: "create"
        }
      });
    },
    viewItem: function(id) {
      this.$router.push({
        name: "Chain submission",
        params: {
          action: "view",
          id: id
        }
      });
    },
    editItem: function(id) {
      this.$router.push({
        name: "Chain submission",
        params: {
          action: "edit",
          id: id
        }
      });
    },
    deleteItem: async function(id) {
      let item = this.dataTable.dataSet.find(item => item.ID === id);

      let confirm = await this.$form.showConfirmation(
        `Chain "${item["Name"]}" will be deleted. Do you want to proceed?`
      );

      if (!confirm) return;

      let self = this;

      this.$api
        .delete(`chains/${item.ID}`)
        .then(response => {
          self.$form.makeToastInfo(response.message);

          self.getData(self.appliedFilters);
        })
        .catch(response => {
          console.log(response);

          self.$form.makeToastError(response.message);
        });
    },
    async blacklistItem(id) {
      let idx = this.dataTable.dataSet.findIndex(item => item.ID === id);

      let chain = this.dataTable.dataSet[idx];

      chain.isLoading = true;
      Vue.set(this.dataTable.dataSet, idx, chain);

      let res = await this.$store.dispatch(
        "chains/addKeyword2Blacklist",
        chain["Name"]
      );

      chain.isLoading = false;
      Vue.set(this.dataTable.dataSet, idx, chain);

      this.$form.makeToastInfo(res.message);
    },
    async editBlackList() {
      this.$refs["blacklisted-kw-modal"].show();

      this.loadKeywords();
    },
    async loadKeywords() {
      this.blacklistModal.isLoading = true;

      let res = await this.$store.dispatch("chains/getBlacklistedKeywords");

      this.blacklistModal.isLoading = false;

      this.blacklistModal.data = res.map(k => k.keyword).join("\n");
    },
    async updateKeywords() {
      let data = this.blacklistModal.data
        .split("\n")
        .filter(i => i.trim() !== "");

      this.blacklistModal.isLoading = true;

      let res = await this.$store.dispatch(
        "chains/updateBlacklistedKeywords",
        data
      );

      this.$form.makeToastInfo(res.message);

      await this.loadKeywords();

      this.blacklistModal.isLoading = false;
    },
    onBlacklistChange(id, value) {
      this.blacklistModal.data = value;
    },
    async saveBlackListModal() {
      await this.updateKeywords();
    },
    closeBlackListModal() {
      this.$refs["blacklisted-kw-modal"].hide();
    }
  },
  watch: {}
};
</script>

<style scoped></style>
